module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de","en","es","fr","nl","pl","ru","tr","uk"],"defaultLanguage":"de","siteUrl":"https://tipgame.com/","trailingSlash":"always","i18nextOptions":{"ns":["header","ticket","footer","errors","tip-game"],"whitelist":["de","en","uk","pl","nl","tr","fr","es","ru"],"debug":true,"fallbackLng":"de","detection":{"order":["localStorage","htmlTag","cookie","path"],"lookupFromPathIndex":0,"checkWhitelist":true},"supportedLngs":["de","en","es","fr","nl","pl","ru","tr","uk"],"defaultNS":"header","interpolation":{"escapeValue":false},"nsSeparator":false},"pages":[{"matchPath":"/:lang?/blog","getLanguageFromPath":true},{"matchPath":"/","languages":["de"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tip-Game","short_name":"Tip-Game","description":"DIE KOSTENLOSE TIP-PLATTFORM","start_url":"./","background_color":"#ffffff","theme_color":"#000000","display":"standalone","form_factor":"wide","icons":[{"src":"favicon-16x16.ico","sizes":"16x16","type":" \"image/x-icon\""},{"src":"favicon-32x32.ico","sizes":"32x32","type":"image/x-icon"},{"src":"logo192.png","type":"image/png","sizes":"192x192","purpose":"any"},{"src":"logo512.png","type":"image/png","sizes":"512x512"},{"src":"maskable_icon.png","type":"image/png","sizes":"512x512","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"6751666381611603","trackViewContent":true,"trackPage":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
